import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { isToday } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import BottomNavSales from 'sales/BottomNavSales';
import { useGState } from 'state/store';
import { useBranchId, useDate } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { formatDayTime } from 'utils/utils';
import useWatchLocation from 'utils/watchLocation';
import { Loader } from '../../node_modules/rsuite/lib/index';
import CancelIcon from '@material-ui/icons/Cancel';

export const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 60 * 1,
  maximumAge: 1000 * 3600 * 24,
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SalesAudit() {
  const branchId = useBranchId();
  const user = useGState((s) => s.user);
  const date = useDate();
  const [trackingEvents, setTrackingEvents] = useState([]);
  const [message, setMessage] = useState({ label: '', value: '' });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const isOldDate = !isToday(new Date(date));

  const { data, status } = useQuery(`auditing_salesmen_${user.id}`, () =>
    fetch(`/supervisor/${user.id}/salesman`)
  );

  const { refetch: refetchTrackingEvents, isFetching: isLoadingtrackingEvents } = useQuery(
    `tracking_events_${user.id}_${date}`,
    () => fetch(`/supervisor/${user.id}/trackingEvents?date=${date}`),
    {
      onSuccess: (resp) => setTrackingEvents(resp[0]?.activities?.softEvents),
      refetchInterval: 20000,
    }
  );

  const {
    data: { trackingEnabled: isInMarket } = {},
    isFetching: isLoadingtrackingState,
    refetch: refetchTrackingState,
  } = useQuery(`/${user.id}/tracking`, () => fetch(`/supervisor/${user.id}/tracking`));

  const [setInMarket] = useMutation(
    (trackingEnabled) =>
      fetch({
        url: `/supervisor/${user.id}/tracking`,
        method: 'POST',
        data: { trackingEnabled },
      }),
    {
      onSuccess: () => refetchTrackingState(),
    }
  );

  const { data: saleRep, status: saleRepStatus, refetch: refetchSaleReps } = useQuery(
    `auditing_saleRep_${user.id}`,
    () => fetch(`/supervisor/${user.id}/salesmanBeat?date=${date}`),
    {
      onSuccess: (data) => {
        getBeats(data[data.length - 1].salesman.salesmanRoleId);
      },
    }
  );
  const { location, timestamp, accuracy } = useWatchLocation(geolocationOptions, isInMarket);

  let salesmenData = [
    { label: 'Sale Rep', value: 'Sale Rep', accRoleId: 'Sale Rep', nodeName: 'Sale Rep' },
  ];
  salesmenData = salesmenData.concat(
    data?.map((s) => ({
      label: s.name,
      value: s.id,
      accRoleId: s.accountRoleId,
      nodeName: s.nodeName,
    }))
  );

  let branches = [{ label: 'Select Branch', value: 'branch' }];
  const uniqueNodesSet = new Set(data?.map(({ nodeName }) => nodeName));
  branches = branches.concat(
    Array.from(uniqueNodesSet, (nodeName) => ({ label: nodeName, value: nodeName }))
  );

  branches.push({ label: 'Non PDA Branch', value: 'nonPDA' });

  const [selectedBranch, setSelectedBranch] = useState(branches[0]);

  const [salesman, setSalesman] = useState(salesmenData[0]);

  const [getBeats, isLoading] = useMutation(
    (roleId) =>
      fetch({
        url: `/supervisor/beats?salesmanId=${salesman.value}`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: [roleId],
      }),
    {
      onSuccess: (data) => {
        setBeats(
          beatsData.concat(
            data.map((d) => ({ label: d.beat, value: d.beat, retailers: d.retailers }))
          )
        );
      },
    }
  );
  let beatsData = [{ label: 'Beat', value: 'beat', retailers: [] }];
  const [beats, setBeats] = useState(beatsData[0]);
  const [selectedBeat, setSelectedBeat] = useState(beats[0]);
  const [openDialog, setOpenDialog] = useState(false);

  const [postTracking] = useMutation(
    (event) =>
      fetch({
        url: `/v2/user/tracking`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: event
          ? {
              locations: [
                {
                  latitude: Number(event.latitude),
                  longitude: Number(event.longitude),
                  accuracy: event.accuracy,
                  timestamp: Date.now(),
                },
              ],
              state: { timestamp: Date.now() },
            }
          : {
              locations: [
                {
                  latitude: Number(location.latitude),
                  longitude: Number(location.longitude),
                  accuracy,
                  timestamp,
                },
              ],
              state: { timestamp },
            },
      }),
    {
      onSuccess: () => {
        setTimeout(() => {
          refetchTrackingEvents();
        }, 4000);
      },
    }
  );

  useEffect(() => {
    if (!location) return;
    postTracking();
  }, [location, postTracking]);

  const handleCloseDialog = () => setOpenDialog(false);

  const onMarketIn = () => setInMarket(true);

  const stopTracking = () => {
    setInMarket(false);
    handleCloseDialog();
    if (trackingEvents.length > 0) {
      postTracking(trackingEvents[0]);
    }
    setTimeout(() => {
      refetchTrackingEvents();
    }, 4000);
    // refetchTrackingEvents();
    // postTracking(Date.now());
  };

  const [saveSaleRep, { isLoading: savingSaleRep }] = useMutation(
    () =>
      fetch({
        url:
          salesman.value === 'Non-PDA'
            ? `supervisor/${user.id}/metrics`
            : `/supervisor/${user.id}/salesmanBeat?date=${date}`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data:
          salesman.value === 'Non-PDA'
            ? { date: date }
            : {
                salesmanId: salesman.value,
                beatName: selectedBeat.label,
                accountRoleId: salesman.accRoleId,
              },
      }),
    { onSuccess: () => refetchSaleReps() }
  );

  const [deleteBeat] = useMutation(
    ({ saleRep }) =>
      fetch({
        url: `/supervisor/${saleRep.id}/cancel`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
      }),
    { onSuccess: () => refetchSaleReps() }
  );
  const onSalesmanChange = (e) => {
    setSalesman(e);
    if (e.value !== 'Sale Rep' && e.value !== 'Non-PDA') getBeats(e.accRoleId);
  };
  const onBranchChange = (e) => {
    setSelectedBranch(e);
    setSalesman(salesmenData[0]);
    if (e.value === 'nonPDA')
      setSalesman({
        label: 'Non PDA Sale Rep',
        value: 'Non-PDA',
        accRoleId: 'Non PDA Sale Rep',
      });
  };

  const onBeatChange = (e) => {
    setSelectedBeat(e);
  };

  const onMarketOut = () => {
    if (salesman.value === 'Non-PDA' && message.label !== 'success') {
      setOpenSnackbar(true);
      setMessage({ label: 'error', value: 'Set values and save' });
      return;
    }
    if (salesman.value !== 'Non-PDA' && !selectedBeat) {
      setOpenSnackbar(true);
      setMessage({ label: 'error', value: 'Select branch, salesman and beat' });
      return;
    }
    setOpenDialog(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  if (status !== 'success' || saleRepStatus === 'loading')
    return <LinearProgress color="secondary" />;
  return (
    <div className="bg-white_secondary pb-16">
      {isLoadingtrackingState || isLoadingtrackingEvents ? (
        <LinearProgress color="secondary" />
      ) : (
        <div className="h-1"></div>
      )}
      <div className="mx-4 my-2">
        <div className="flex items-stretch justify-evenly bg-white rounded-lg mb-5">
          <div className="flex flex-col gap-1 items-center py-3">
            <div>
              {trackingEvents.length > 0
                ? formatDayTime(new Date(trackingEvents[0].timestamp))
                : '--'}
            </div>
            <button
              className={clsx(
                'text-xs rounded-md p-2 text-white px-4',
                !isInMarket && !isOldDate ? 'bg-green-600' : 'bg-gray-600'
              )}
              onClick={onMarketIn}
              disabled={isInMarket || isOldDate}
            >
              Market In
            </button>
          </div>
          <div className="bg-white_secondary w-1" />
          <div className="flex flex-col gap-1 items-center py-3">
            <div>
              {trackingEvents.length > 1 && !isInMarket
                ? formatDayTime(new Date(trackingEvents[trackingEvents.length - 1].timestamp))
                : '--'}
            </div>
            <button
              className={clsx(
                'text-xs rounded-md p-2 text-white px-4',
                isInMarket && !isOldDate ? 'bg-green-600' : 'bg-gray-600'
              )}
              onClick={onMarketOut}
            >
              Market Out
            </button>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <Select options={branches} value={selectedBranch} onChange={onBranchChange} />
          {selectedBranch?.value == 'branch' ? null : (
            <Select
              key={salesman}
              options={
                selectedBranch.value === 'nonPDA'
                  ? salesman
                  : salesmenData.filter((s) =>
                      [selectedBranch.value, 'Sale Rep'].includes(s.nodeName)
                    )
              }
              value={salesman}
              onChange={selectedBranch.value === 'nonPDA' ? null : onSalesmanChange}
            />
          )}
          {salesman.value === 'Non-PDA' ? (
            <div className="bg-salesSub rounded-md text-gray_secondary items-center justify-center flex w-full">
              Non PDA
            </div>
          ) : salesman.value === 'Sale Rep' ? null : isLoading.status === 'success' ? (
            <Select options={beats} value={selectedBeat} onChange={onBeatChange} />
          ) : (
            <Loader />
          )}
          <div />
          <button
            className={clsx(
              'text-md md:text-base md:py-0 rounded-md font-abyss bg-green-600 col-span-2 py-2 text-white md:col-span-1'
            )}
            onClick={saveSaleRep}
            disabled={savingSaleRep}
          >
            Save
          </button>
        </div>
        {salesman.value === 'Non-PDA' ? (
          <NonPDA
            supervisorId={user.id}
            trackSalesman={isInMarket}
            message={message}
            setMessage={setMessage}
            openSnackbar={openSnackbar}
            setOpenSnackbar={setOpenSnackbar}
          />
        ) : (
          <>
            <SavedSaleRep
              key={saleRep}
              deleteBeat={deleteBeat}
              savedSaleReps={saleRep.filter((o1) =>
                salesmenData.some((o2) => o1.salesmanId == o2.value && (o1['label'] = o2.label))
              )}
            />
            <Retailers retailers={selectedBeat} />
            <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={message.label}>
                {message.value}
              </Alert>
            </Snackbar>
          </>
        )}
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Tracking</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to disable tracking ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={stopTracking}>Disable tracking</Button>
        </DialogActions>
      </Dialog>
      <BottomNavSales index={2} />
    </div>
  );
}

function SavedSaleRep({ savedSaleReps, deleteBeat }) {
  const [openDialogBeat, setOpenDialogBeat] = useState({ status: false, i: 'none' });
  const handleCloseDialogBeat = () => setOpenDialogBeat({ status: false, i: 'none' });

  return (
    <div className="grid gap-3 mt-4 bg-gray-300 p-2 rounded-lg">
      {savedSaleReps.map((saleRep, i) => (
        <div
          className="bg-white rounded-lg p-5 grid relative items-center justify-between grid-cols-12 gap-4"
          key={saleRep.salesmanId + saleRep.mktName}
        >
          <div className="col-span-6">{saleRep.label}</div>
          <div className="text-grey_tertiary text-xs col-span-5">{saleRep.mktName}</div>
          <CancelIcon
            color="secondary"
            className="absolute top-0 right-0"
            onClick={() => setOpenDialogBeat({ status: true, i: i })}
          />
        </div>
      ))}
      <Dialog open={openDialogBeat.status} onClose={handleCloseDialogBeat}>
        <DialogTitle>Delete Beat</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {savedSaleReps[openDialogBeat.i]?.label} with{' '}
            {savedSaleReps[openDialogBeat.i]?.mktName} beat ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogBeat}>Cancel</Button>
          <Button onClick={() => deleteBeat({ saleRep: savedSaleReps[openDialogBeat['i']] })}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const NonPDADetails = [
  { label: 'Bill Cut', value: '' },
  { label: 'TLSD', value: '' },
  { label: 'Value', value: '' },
];

function NonPDA({
  supervisorId,
  trackSalesman,
  message,
  setMessage,
  openSnackbar,
  setOpenSnackbar,
}) {
  const [NonPDAVals, setNonPDAVals] = useState(NonPDADetails);
  const handleValChange = (val, detail) => {
    let newPDAVals = NonPDAVals.filter((val) => val.label === detail.label)[0];
    newPDAVals.value = val.target.value === '' ? '' : Number(val.target.value);
    setNonPDAVals(NonPDAVals.filter((val) => (val.label === detail.label ? newPDAVals : val)));
  };

  const date = useDate();
  const branchId = useBranchId();

  const [saveOrderBooking] = useMutation(
    () =>
      fetch({
        url: `/supervisor/${supervisorId}/metrics`,
        method: 'POST',
        headers: { 'x-wh-id': branchId },
        data: {
          date: date,
          ordersCount: NonPDAVals[0].value,
          linesCount: NonPDAVals[1].value,
          salesValue: NonPDAVals[2].value,
        },
      }),
    {
      onSuccess: () => {
        setOpenSnackbar(true);
        setMessage({ label: 'success', value: 'Saved successfully' });
      },
    }
  );

  const checkOrder = () => {
    if (NonPDAVals.filter((val) => val.value === '').length > 0) {
      setOpenSnackbar(true);
      setMessage({ label: 'error', value: 'Enter all values' });
      return;
    }
    saveOrderBooking();
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="mt-6 font-abyss">
      <div className="text-base">Today&apos;s Order Booking</div>
      {NonPDADetails.map((detail) => (
        <div
          key={detail.label}
          className="bg-white py-3 rounded-lg pl-8 flex mt-6 items-center justify-between"
        >
          <div>{detail.label}</div>
          <input
            type="number"
            placeholder="Add value"
            className="float-right"
            disabled={!trackSalesman}
            value={NonPDAVals.filter((val) => val.label === detail.label)[0].value}
            onChange={(e) => handleValChange(e, detail)}
          />
        </div>
      ))}
      <button
        className="mt-8 bg-blue_secondary text-white w-full rounded-lg py-4 text-base"
        onClick={checkOrder}
      >
        Save
      </button>
      <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={message.label}>
          {message.value}
        </Alert>
      </Snackbar>
    </div>
  );
}

function Retailers({ retailers }) {
  if (retailers?.retailers?.length === 0) return null;
  return (
    <div className="grid gap-3 mt-2 pb-20">
      {retailers?.retailers?.map((r) => (
        <div className="bg-white rounded-lg p-5" key={r.id}>
          <div>{r.name}</div>
          <div className="text-grey_tertiary text-xs mt-1">{r.code}</div>
        </div>
      ))}
    </div>
  );
}
