import { useEffect, useRef, useState } from 'react';
import { usePrevious } from './customHooks';

const useWatchLocation = (options = {}, track) => {
  const [location, setLocation] = useState();
  const [timestamp, setTimeStamp] = useState();
  const [accuracy, setAccuracy] = useState();
  const locationWatchId = useRef(null);
  const prevLoc = usePrevious(location);

  const cancelLocationWatch = () => {
    const { geolocation } = navigator;

    if (locationWatchId.current && geolocation) {
      geolocation.clearWatch(locationWatchId.current);
    }
  };
  const handleError = () => {};
  useEffect(() => {
    const { geolocation } = navigator;
    locationWatchId.current = geolocation.watchPosition(
      (pos) => {
        const latitude = pos.coords.latitude,
          longitude = pos.coords.longitude,
          accuracy = pos.coords.accuracy;
        const { timestamp } = pos;

        if (prevLoc === location && location !== undefined) return;
        setLocation({
          latitude,
          longitude,
        });
        setTimeStamp(timestamp);
        setAccuracy(accuracy);
      },
      handleError,
      options
    );

    return cancelLocationWatch;
  }, [options, location, prevLoc]);

  if (!track)
    return {
      location: 0,
      timestamp: 0,
      cancelLocationWatch: () => undefined,
    };
  return { location, timestamp, accuracy, cancelLocationWatch };
};

export default useWatchLocation;
