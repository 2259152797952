import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import SalesAudit from 'sales/SalesAudit';

export default (props) => (
  <Router>
    <Component path="/sales/audit" layoutProps={props} />
  </Router>
);

const Component = ({ layoutProps }) => {
  return (
    <Layout {...layoutProps} hideNodeSelector title="Follow">
      <ReactQueryConfigProvider
        config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
      >
        <SalesAudit />
      </ReactQueryConfigProvider>
    </Layout>
  );
};
